import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>Página no encontrada</h1>
    <p>No se ha podido encontrar la página que buscas.</p>
    <p>Volver a la <a href="/">home</a></p>
  </Layout>
)

export default NotFoundPage
